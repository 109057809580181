import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';

const Dining = ({ data }) => {
  const { nodeDining, allNodeEvent } = data; 

  // Filter events for this dining location
  const diningEvents = allNodeEvent.edges.filter(
    ({ node }) =>
      node.relationships.venue &&
      node.relationships.venue.__typename === "node__dining" &&
      node.relationships.venue.drupal_id === nodeDining.drupal_id
  );

  return (
    <DefaultLayout data={data}>
      <SEO
        title={nodeDining.title}
        siteConfig={data.site.siteMetadata}
        pageUrl={nodeDining.path.alias}
      />
      <ContentDisplay
        {...nodeDining}
        imageLocation="sidebar"
        upcomingEvents={diningEvents.map(({ node }) => node)}
      />
    </DefaultLayout>
  );
};


Dining.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
 query DiningQuery($slug: String!) {
  site {
    siteMetadata {
      title
      siteUrl
    }
  }
  nodeDining(path: { alias: { eq: $slug } }) {
    title
    drupal_id
    path {
      alias
    }
    body {
      processed
      summary
    }
    metatag: metatag {
      attributes {
        name
        content
      }
    }
    field_image {
      alt
      imageDerivatives {
        links {
          max_1600_10_7 {
            href
          }
          max_1600_16_9 {
            href
          }
        }
      }
    }
    relationships {
      field_image {
        max_1600_10_7 {
          childImageSharp {
            resolutions(width: 470, height: 330, quality: 90) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
        max_1600_16_9 {
          childImageSharp {
            sizes(maxWidth: 992, maxHeight: 558) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      field_cuisine {
        metatag {
          attributes {
            content
          }
        }
      }
      field_distance {
        metatag {
          attributes {
            content
          }
        }
      }
      field_experience {
        metatag {
          attributes {
            content
          }
        }
      }
    }
  }
  allNodeEvent(
    sort: { fields: [field_date___value], order: ASC }
    filter: { field_private_event: { ne: true } }
  ) {
    edges {
      node {
        title
        field_date {
          value(formatString: "MMMM D, YYYY")
          end_value(formatString: "MMMM D, YYYY")
        }
        field_image {
          imageDerivatives {
            links {
              max_1600_16_9 {
                href
              }
            }
          }
        }
        path {
          alias
        }
        relationships {
          venue: field_venue {
            ... on VenueUnion {
                ... on node__dining {
                  title
                  drupal_id
                  path {
                    alias
                  }
                }            
              }
          }
        }
      }
    }
  }

}
`;



export default Dining;
